import G6 from "@antv/g6";

// let positive_color = "#ee4f4f";
// let negative_color = "#76b852";
let positive_color = "#76b852";
let negative_color = "#ee4f4f";
let prohibited_color = "#cdc7fc";
let categorical_color = "#aaa";
let default_color = "#e2e2e2";
let background_color = "#F4F7F9";

//---------
// edge style
//---------

let Expert_Edge_Style = {
  opacity: 0.8,
  lineDash: [0, 0],
  stroke: default_color,
  lineAppendWidth: 2,
  endArrow: {
    path: G6.Arrow.triangleRect(5, 5, 5, 3, 15, 55), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
    d: 55,
    lineDash: [0, 0],
  },
};

let AI_Edge_Style = {
  opacity: 0.8,
  lineDash: [10, 1],
  stroke: default_color,
  lineAppendWidth: 2,
  endArrow: {
    path: G6.Arrow.triangle(5, 5, 55), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
    d: 55,
    lineDash: [0, 0],
  },
};

//---------
// edge state
//---------

let State_Par = {
  opacity: 0.8,
  labelCfg: {
    fontSize: 20,
    fontFamily: "sans-serif",
    opacity: 0.8,
  },
};

let Edge_Positive_State = {
  stroke: positive_color,
  ...State_Par,
};

let Edge_Negative_State = {
  stroke: negative_color,
  ...State_Par,
};

let Edge_Prohibited_State = {
  stroke: prohibited_color,
  ...State_Par,
};

let Edge_Categorical_State = {
  stroke: categorical_color,
  ...State_Par,
};

//---------
// node style
//---------

let Default_Node_style = {
  opacity: 0.5,
  lineWidth: 5,
  fill: "#DEE9FF",
  stroke: "#168eea",
};

//---------
// node state
//---------

let Node_Highlight_State = {
  opacity: 1,
  stroke: "#168eea",
  lineWidth: 5,
  labelCfg: {
    style: {
      fill: "#ced7df",
      fontSize: 20,
      fontFamily: "sans-serif",
    },
  },
};

let Node_Dark_State = {
  opacity: 0.1,
  lineWidth: 5,
  labelCfg: {
    style: {
      fill: "#ced7df",
      fontSize: 10,
      fontFamily: "sans-serif",
    },
  },
};

export {
  Default_Node_style,
  AI_Edge_Style,
  Expert_Edge_Style,
  Edge_Positive_State,
  Edge_Negative_State,
  Edge_Prohibited_State,
  Edge_Categorical_State,
  Node_Highlight_State,
  Node_Dark_State,
  positive_color,
  negative_color,
  prohibited_color,
  categorical_color,
  default_color,
  background_color,
};
