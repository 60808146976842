<template>
  <div>
    <div
      id="dag"
      class="ma-0"
      style="background-color: #F4F7F9; height: 100vh"
    />
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" id="cauasl_graph_guide">
          mdi-information-outline
        </v-icon>
      </template>
      Show tour guide
    </v-tooltip>

    <!-- </template> -->
  </div>
</template>
<script>
import data from "@/models/data";
//import axios from "axios";
import G6 from "@antv/g6";
const { Algorithm } = require("@antv/g6");
const { breadthFirstSearch } = Algorithm;

import {
  Default_Node_style,
  AI_Edge_Style,
  Expert_Edge_Style,
  Edge_Positive_State,
  Edge_Negative_State,
  Edge_Prohibited_State,
  Edge_Categorical_State,
  Node_Highlight_State,
  Node_Dark_State,
  positive_color,
  negative_color,
  categorical_color,
  default_color,
  background_color,
} from "./edgeStyle.js";

export default {
  name: "DatasetGroupsCausalGraph",
  props: {
    nodeSelect: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      width: "",
      height: "",
      edgeType: "line",
      is_acyclic: false,
      g: null,
      gData: {},
      graph: "",
      toolbar: "",
      container: "dag",
      url: "",
      loaded: false,
      hasNoResources: null,
      weightArr: [], //importance list value of node
      onAddNode: null,
      nodes: null,
      edges: null,
    };
  },
  components: {},
  computed: {},
  watch: {
    gData: {
      handler(value) {
        // console.log("LOADED GDATA");
        // console.log(value);
        this.$nextTick(() => {
          if ("nodes" in value) {
            // console.log("value", value);
            this.renderData(this.gData);
            // get click node
            this.graph.on("node:click", (evt) => {
              const targetNode = evt.item && evt.item.getModel();
              // console.log(targetNode.id);
              // console.log("targetNode");
              // console.log(targetNode);
              this.$emit("targetNodeDesc", targetNode.desc);
              this.$emit("targetNode", targetNode.id);
              this.$emit("nodeImportance", this.getWeightArr());
            });
            // get click edge
            this.graph.on("edge:click", (evt) => {
              const targetEdge = evt.item && evt.item.getModel();
              // console.log("targetEdge");
              // console.log(targetEdge);
              this.$emit("targetEdge", targetEdge);
            });
          }
        });
      },
    },
    immediate: true,
    description: {
      handler(value) {
        // console.log("description changed");
        // console.log(this.nodeSelect);
        // console.log(value);
        const node = this.gData.nodes.filter((o) => {
          return o.id === this.nodeSelect;
        });
        // console.log("node");
        // console.log(node);
        node[0].desc = value;
        // console.log(node.desc);
        // const node1 = this.gData.nodes.filter((o) => {
        // return o.id === this.nodeSelect;
        // });
        // console.log(node1[0].desc);
      },
    },
  },
  methods: {
    //-------------------------------------
    // initial G6
    //-------------------------------------
    initG6() {
      this.gData = {};
      G6.registerEdge(
        "circle-running-line",
        {
          afterDraw(cfg, group) {
            // get the first shape in the group, it is the edge's path here=
            const shape = group.get("children")[0];
            // the start position of the edge's path
            const startPoint = shape.getPoint(0);

            // add red circle shape
            const circle = group.addShape("circle", {
              attrs: {
                x: startPoint.x,
                y: startPoint.y,
                fill: "#168eea",
                r: 4,
              },
              name: "circle-shape",
            });

            // animation for the red circle running
            circle.animate(
              (ratio) => {
                // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
                // get the position on the edge according to the ratio
                const tmpPoint = shape.getPoint(ratio);
                // returns the modified configurations here, x and y here
                return {
                  x: tmpPoint.x,
                  y: tmpPoint.y,
                };
              },
              {
                repeat: true, // Whether executes the animation repeatly
                duration: 3000, // the duration for executing once
              }
            );
          },
        },
        "line"
      );

      G6.registerEdge(
        "circle-running-quadratic",
        {
          afterDraw(cfg, group) {
            // get the first shape in the group, it is the edge's path here=
            const shape = group.get("children")[0];
            // the start position of the edge's path
            const startPoint = shape.getPoint(0);

            // add red circle shape
            const circle = group.addShape("circle", {
              attrs: {
                x: startPoint.x,
                y: startPoint.y,
                fill: "#168eea",
                r: 4,
              },
              name: "circle-shape",
            });

            // animation for the red circle running
            circle.animate(
              (ratio) => {
                // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
                // get the position on the edge according to the ratio
                const tmpPoint = shape.getPoint(ratio);
                // returns the modified configurations here, x and y here
                return {
                  x: tmpPoint.x,
                  y: tmpPoint.y,
                };
              },
              {
                repeat: true, // Whether executes the animation repeatly
                duration: 3000, // the duration for executing once
              }
            );
          },
        },
        "quadratic"
      );

      //--------------moude left click menu------------------
      const contextMenu = new G6.Menu({
        getContent(evt) {
          let header;
          let menuBody;
          if (evt.target && evt.target.isCanvas && evt.target.isCanvas()) {
            header = "Canvas ContextMenu";
          } else if (evt.item) {
            const itemType = evt.item.getType();
            header = `${itemType.toUpperCase()} Edit`;
            const datatype = evt.item.getModel()?.details?.datatype;
            if (itemType.toUpperCase() === "NODE" && datatype === "category") {
              menuBody = `
              <h3>${header}</h3>
              <ul style="list-style-type: none;margin-left: 0;padding-left: 0;">
                <li code="add_X_edge">➕ Add Prohibited Edge</li>
                <li code="add_C_edge">➕ Add Categorical Edge</li>
              </ul>`;
            } else if (
              itemType.toUpperCase() === "NODE" &&
              datatype !== "category"
            ) {
              menuBody = `
              <h3>${header}</h3>
              <ul style="list-style-type: none;margin-left: 0;padding-left: 0;">
                <li code="add_P_edge">➕ Add Positive Edge</li>
                <li code="add_N_edge">➕ Add Negative Edge</li>
                <li code="add_X_edge">➕ Add Prohibited Edge</li>
                <li code="add_C_edge">➕ Add Categorical Edge</li>
              </ul>`;
            } else {
              menuBody = `
              <h3>${header}</h3>
              <ul style="list-style-type: none;margin-left: 0;padding-left: 0;">
                <li code="delete_edge">❌ Delete</li>
                <li code="reverse_edge">🔁 Reverse</li>
              </ul>`;
            }
          }

          return menuBody;
        },
        handleMenuClick: (target, item) => {
          let attributeCode = target.getAttribute("code");
          let itemData = item.getModel();
          let originData = this.graph.save();

          // console.log("attributeCode", attributeCode);

          if (attributeCode == "delete_edge") {
            originData.edges = originData.edges.filter((edge) => {
              return edge.id !== itemData.id;
            });
          }

          if (attributeCode == "reverse_edge") {
            originData.edges = originData.edges.map((edge) => {
              if (edge.id == itemData.id) {
                if (itemData.edgeStyle === "ai") {
                  edge.rawEdgeStyle = "ai";
                  edge.edgeStyle = "expert";
                  edge.style = Expert_Edge_Style;
                } else if (
                  itemData.edgeStyle === "expert" &&
                  itemData?.rawEdgeStyle === "ai"
                ) {
                  edge.edgeStyle = "ai";
                  edge.style = AI_Edge_Style;
                }
                let temp = edge.source;
                edge.source = edge.target;
                edge.target = temp;
              }
              return Object.assign({}, edge);
            });
          }

          if (attributeCode == "add_P_edge") {
            this.code = "add_P_edge";
            originData.nodes.find((item, index, arr) => {
              if (item.id == itemData.id) {
                // console.log("selectedNode", arr[index]);
                this.onAddNode = arr[index].id;
              }
            });
          }

          if (attributeCode == "add_N_edge") {
            this.code = "add_N_edge";
            originData.nodes.find((item, index, arr) => {
              if (item.id == itemData.id) {
                // console.log("selectedNode", arr[index]);
                this.onAddNode = arr[index].id;
              }
            });
          }

          if (attributeCode == "add_X_edge") {
            this.code = "add_X_edge";
            originData.nodes.find((item, index, arr) => {
              if (item.id == itemData.id) {
                // console.log("selectedNode", arr[index]);
                this.onAddNode = arr[index].id;
              }
            });
          }

          if (attributeCode == "add_C_edge") {
            this.code = "add_C_edge";
            originData.nodes.find((item, index, arr) => {
              if (item.id == itemData.id) {
                // console.log("selectedNode", arr[index]);
                this.onAddNode = arr[index].id;
              }
            });
          }

          this.graph.changeData(originData);
          this.graph.refresh();
          // update the cloneData
          this.cloneData(originData);
        },
        // offsetX and offsetY include the padding of the parent container
        // 需要加上父级容器的 padding-left 16 与自身偏移量 10
        offsetX: 16 + 10,
        // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
        offsetY: 0,
        // the types of items that allow the menu show up
        // 在哪些类型的元素上响应
        itemTypes: ["node", "edge", "canvas"],
      });

      //--------------hover------------------
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        fixToNode: [1, 0.5],
        // the types of items that allow the tooltip show up
        // 允许出现 tooltip 的 item 类型
        itemTypes: ["node", "edge"],
        // custom the tooltip's content
        // 自定义 tooltip 内容
        getContent: (e) => {
          const outDiv = document.createElement("div");
          outDiv.style.width = "fit-content";
          outDiv.style.height = "fit-content";
          const model = e.item.getModel();

          if (e.item.getType() === "node") {
            outDiv.innerHTML = `<div style="font-size: 20px;font-family: Helvetica, sans-serif">${model.desc}</div>`;
          } else {
            const source = e.item.getSource();
            const target = e.item.getTarget();
            outDiv.innerHTML = `<div style="font-size: 18px;font-family: Helvetica, sans-serif">From：${
              source.getModel().label
            }<br/>To：${target.getModel().label}<br/>Weight: ${
              e.item.getModel().weight
            }</div>`;
          }
          return outDiv;
        },
      });

      //--------------legend------------------
      const legendData = {
        nodes: [
          {
            id: "positive",
            label: "Positive",
            order: 0,
            style: {
              fill: positive_color,
            },
          },
          {
            id: "negative",
            label: "Negative",
            order: 1,
            style: {
              fill: negative_color,
            },
          },
          {
            id: "category",
            label: "Category",
            order: 2,
            style: {
              fill: categorical_color,
            },
          },
          {
            id: "unselected",
            label: "Unselected",
            order: 3,
            style: {
              fill: default_color,
            },
          },
          {
            id: "prohibited",
            label: "X Prohibited",
            type: "ellipse",
            order: 3,
          },
        ],
        edges: [
          {
            id: "AI",
            label: "AI",
            type: "line",
            size: 120,
            style: Object.assign({}, AI_Edge_Style, {
              stroke: "#aaa",
              lineWidth: 3,
            }),
          },
          {
            id: "expert",
            label: "Expert",
            type: "line",
            size: 120,
            style: Object.assign({}, Expert_Edge_Style, {
              stroke: "#aaa",
              lineWidth: 3,
            }),
          },
        ],
      };

      const legend = new G6.Legend({
        data: legendData,
        align: "center",
        layout: "horizontal", // vertical
        position: "top-left",
        vertiSep: 24,
        horiSep: 12,
        offsetX: 0,
        offsetY: 0,
        padding: [4, 16, 8, 16],
        containerStyle: {
          fill: background_color,
          lineWidth: 1,
        },
        title: " ",
        titleConfig: {
          offsetY: -8,
        },
      });

      const DAG = document.getElementById("dag");

      // const height =
      //   (DAG.scrollHeight || document.documentElement.scrollHeight) * 1;

      this.width = DAG.offsetWidth;
      this.height = DAG.offsetHeight;

      // console.log("width:" + this.width);
      // console.log("height:" + this.height);

      //--------------G6 Tool Bar------------------
      const toolbar = new G6.ToolBar({
        className: "g6-toolbar-ul",
        getContent: () => {
          return `
      <ul>
      </ul>
    `;
        },
      });

      this.toolbar = toolbar;

      //--------------G6 initialize------------------
      const edgeType = this.is_acyclic ? "line" : "quadratic";

      const graph = new G6.Graph({
        enabledStack: true,
        container: this.container,
        width: this.width | 800,
        height: this.height,
        fitView: true,
        linkCenter: true, //Peter
        // fitViewPadding: [40, 40, 40, 40],
        plugins: [tooltip, legend, contextMenu, toolbar],
        animate: false,
        layout: {
          type: "forceAtlas2",
          preventOverlap: true,
          kr: 200,
          prune: false,
        },
        modes: {
          default: [
            "zoom-canvas",
            "drag-canvas",
            "drag-node",
            { type: "click-select", multiple: false },
          ],
        },
        // default node style
        defaultNode: {
          size: [100, 100],
          style: Default_Node_style,
          labelCfg: {
            style: {
              fill: "#323b43",
              fontSize: 20,
              fontFamily: "sans-serif",
            },
          },
        },

        // default edge style
        defaultEdge: {
          size: 1,
          // type: "line",
          // type: "quadratic",
          type: edgeType,
          style: AI_Edge_Style,
          curveOffset: 60,
          label: "",
          labelCfg: {
            position: "center", // 其实默认就是 center，这里写出来便于理解
            autoRotate: true, // 使文本随边旋转
            style: {
              fontSize: 20,
              fontFamily: "sans-serif",
              fill: "#722ed1",
            },
          },
        },
        // node select & unselect style
        nodeStateStyles: {
          highlight: Node_Highlight_State,
          dark: Node_Dark_State,
        },
        // edge select & unselect style
        edgeStateStyles: {
          positive: Edge_Positive_State,
          negative: Edge_Negative_State,
          prohibited: Edge_Prohibited_State,
          categorical: Edge_Categorical_State,
        },
      });
      // monitor node click event
      graph.on("node:click", (evt) => {
        const targetNode = evt.item && evt.item.getModel();
        if (!targetNode) {
          return;
        }

        // this.onAddNode: first click node
        // targetNode: second click node

        const onAddNode = this.gData.nodes.find((node) => {
          return node.id === this.onAddNode;
        });

        if (
          onAddNode !== null &&
          (onAddNode?.details?.datatype === "numeric" ||
            onAddNode?.details?.datatype === "integer") &&
          this.code === "add_C_edge" &&
          targetNode.details.datatype !== "category"
        ) {
          this.$dialog.message.error(
            "Adding categorical edge can only be applied on a category node.",
            {
              position: "top",
            }
          );
          return;
        }

        if (
          (onAddNode?.details?.datatype === "numeric" ||
            onAddNode?.details?.datatype === "integer") &&
          (this.code === "add_P_edge" || this.code === "add_N_edge") &&
          targetNode.details.datatype === "category"
        ) {
          this.$dialog.message.error(
            "Can only apply categorical edge to a categorical node.",
            {
              position: "top",
            }
          );
          return;
        }

        const targetNodeId = targetNode.id;

        // console.log("onAddNode getModel", this.graph.save());

        // console.log("CALL G6 initialize");
        // console.log(this.onAddNode);

        if (this.onAddNode !== null) {
          //check if the node has exist edge
          if (
            this.findNeighborNodes(this.onAddNode).includes(targetNodeId) ==
            true
          ) {
            // console.log("has an edge");
            // check if node has the same direction line
            if (
              this.findNeighborNodes(this.onAddNode, "target").includes(
                targetNodeId
              ) == true
            ) {
              // console.log("same direction");
              this.onAddNode = null;
              this.$dialog.message.error("Please delete edge first", {
                position: "top",
              });
              return;
            } else {
              // console.log("different direction");
              if (this.is_acyclic == true) {
                // console.log("is_acyclic");

                switch (this.code) {
                  case "add_X_edge":
                    // console.log("X_edge");
                    this.edgeType = "quadratic";
                    break;
                  default:
                    // console.log("others");
                    this.onAddNode = null;
                    this.$dialog.message.error(
                      "It's not allowd to add it in DAG",
                      {
                        position: "top",
                      }
                    );
                    return;
                }
              } else {
                // console.log("isNDAG");
                this.edgeType = "quadratic";
              }
            }
          } else {
            // console.log("no edge");
            this.edgeType = "line";
          }

          // Get the weight between the this.onAddNode: first click node and targetNode: second click node

          const e = this.edges.find((o) => {
            return this.onAddNode === o.from && targetNode.id === o.to;
          });

          let weight = null;
          let size = null;
          let defualtSize = 5;

          // Weight & size
          // TODO: Need to be check in the future if the negative co is the opposite to the positive prior knowledge
          if (e.imp === 0) {
            weight = e.imp;
            size = defualtSize;
          } else {
            if (e.co >= 0) {
              weight = e.imp.toFixed(3);
              size = Math.abs((Number(e.imp) * 10).toFixed(0)) * 1.5;
            } else {
              weight = e.imp.toFixed(3) * -1;
              size = Math.abs((Number(e.imp) * 10).toFixed(0)) * 1.5;
            }
          }

          // add prior knowledge style
          let new_positive_Edge = {
            source: this.onAddNode,
            target: targetNodeId,
            weight: weight,
            size: size,
            type: this.edgeType,
            edge_type: "positive",
            style: Expert_Edge_Style,
            edgeStyle: "expert",
          };

          let new_negative_Edge = {
            source: this.onAddNode,
            target: targetNodeId,
            weight: weight,
            size: size,
            type: this.edgeType,
            edge_type: "negative",
            style: Expert_Edge_Style,
            edgeStyle: "expert",
          };

          let new_prohibited_Edge = {
            source: this.onAddNode,
            target: targetNodeId,
            weight: 0,
            size: size,
            type: this.edgeType,
            edge_type: "prohibited",
            style: Expert_Edge_Style,
            label: "x",
            edgeStyle: "prohibit",
          };

          let new_categorical_Edge = {
            source: this.onAddNode,
            target: targetNodeId,
            weight: weight,
            size: size,
            type: this.edgeType,
            edge_type: "categorical",
            style: Expert_Edge_Style,
            edgeStyle: "expert",
          };

          if (this.code == "add_P_edge") {
            this.graph.addItem("edge", new_positive_Edge);
          } else if (this.code == "add_N_edge") {
            this.graph.addItem("edge", new_negative_Edge);
          } else if (this.code == "add_X_edge") {
            this.graph.addItem("edge", new_prohibited_Edge);
          } else if (this.code == "add_C_edge") {
            this.graph.addItem("edge", new_categorical_Edge);
          }

          // update the cloneData
          this.cloneData(this.graph.save());
          this.clearAllStats();
          this.onAddNode = null;
        }
      });
      graph.on("edge:click", this.selectEdge);
      graph.on("nodeselectchange", this.selectNode);
      graph.on("canvas:click", this.clearAllStats);

      // if (typeof window !== "undefined") {
      //   window.onresize = () => {
      //     if (!this.graph || this.graph.get("destroyed")) return;
      //     if (!DAG || !DAG.scrollWidth || !DAG.scrollHeight) return;
      //     this.graph.changeSize(DAG.scrollWidth, DAG.scrollHeight);
      //   };
      // }
      this.graph = graph;
    },

    //--------------------------------
    // get graph.json from backend
    //--------------------------------
    async getData() {
      try {
        let id = this.$route.params.id;
        if (!id) {
          id = this.$route.query.datasetGroupId;
        }
        // console.log(`id: ${id}`);
        const payload = {
          datasetGroupId: id,
        };
        const resGraph = await data.getCausalGraph(payload); // New expert edge if saved
        const resNodes = await data.getCausalGraphNodes(payload);
        const resEdges = await data.getCausalGraphEdges(payload); // Will not show the saved expert edge weight

        // console.log("resGraph");
        // console.log(resGraph);
        // console.log("resNodes");
        // console.log(resNodes);
        // console.log("resEdges");
        // console.log(resEdges);
        if (resGraph) {
          this.is_acyclic = resGraph.is_acyclic;
          // NonDAG needs quadratic line
          if (!this.is_acyclic) {
            this.edgeType === "quadratic";
          }
          this.initG6();
          this.gData = resGraph;
          this.loaded = true;
        }
        if (resNodes) {
          this.nodes = resNodes.nodes;
          this.gData.nodes = resNodes.nodes;
        }
        if (resEdges) {
          this.edges = resEdges.edges;
          // this.edges.forEach((e) => {

          // })
          // merge data from dag.edges and edges api
          const edges = [];

          this.edges.forEach((edge) => {
            const e = this.gData.edges.find((o) => {
              return edge.from === o.from && edge.to === o.to;
            });
            // console.log("e");
            // console.log(e);
            if (e) {
              // co 的值，在其 from / to 任一節點型別為 category 時，會是 null
              // imp 的值，不會因為使用者上傳的 prior knowledge 而改為 0 或 1，會保留演算法算出的值
              edge.edgeStyle = e.style;
              edge.customCo = e.co;
              edges.push(edge);
            }
          });
          this.gData.edges = edges;
        }
      } catch (error) {
        this.hasNoResources = true;
      } finally {
        this.loaded = true;
      }
    },

    //--------------------------------
    // find neighbor nodes with edge linked
    // par='source' list  source nodes
    // par='target' list  target nodes
    // par=''  list both
    //--------------------------------
    findNeighborNodes(selectNodeID, par) {
      const g = this.graph;
      let neighBorsNodes = [];
      // console.log("findNeighborNodes");
      // console.log(selectNodeID);
      const node = g.findById(selectNodeID);
      let neighBorsNodesArr = node.getNeighbors(par);
      neighBorsNodesArr.forEach((item) => {
        neighBorsNodes.push(item.getModel().id);
      });
      return neighBorsNodes;
    },

    //--------------------------------
    // x use in fake demo
    //--------------------------------
    uploadFakeData(fakeData) {
      this.loaded = true;
      // console.log(fakeData);
      this.gData = fakeData;
      this.renderData(this.gData);
    },

    //--------------------------------
    // toolBar items
    //--------------------------------
    uploadJson(files) {
      if (!files.length) return;
      let reader = new FileReader();
      let json = null;

      reader.readAsText(files[0]);
      reader.onload = () => {
        json = JSON.parse(reader.result);
        this.gData = json;
        this.renderData(this.gData);
        this.$emit("renderFinish", true);
      };
    },

    downloadData() {
      function download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      }

      download(JSON.stringify(this.graph.save()), "graph.json", "text/plain");
    },
    captureImage() {
      this.graph.downloadImage("caslualGraph", "", background_color);
    },
    zoomIn() {
      this.graph.zoom(1.1);
    },
    zoomOut() {
      this.graph.zoom(0.9);
    },
    fitView() {
      this.graph.fitView();
    },
    fitCenter() {
      this.graph.fitCenter();
    },
    redo() {
      this.toolbar.redo();
      // toolbar.redo()
    },
    undo() {
      this.toolbar.undo();
      // toolbar.undo()
    },

    // send the graph json to the backend
    saveGraph() {
      this.graph.save();
      // let graphData = this.graph.save();

      // graphData.edges.forEach((edge) => {
      //   console.log(edge);
      // });

      // graphData.nodes.forEach((node) => {
      //   console.log(node);
      // });
    },

    // getNodesForUpdateDAG()
    getNodesForUpdateDAG() {
      let nodes = [];
      let nodesData = this.graph.save().nodes;
      nodesData.forEach((node) => {
        nodes.push({
          id: node.id,
          desc: node.desc,
        });
      });
      return nodes;
    },

    // getEdgesForUpdateDAG()
    getEdgesForUpdateDAG() {
      let edges = [];
      let edgesData = this.graph.save().edges;
      edgesData.forEach((edge) => {
        if (edge.edgeStyle === "expert") {
          let co = null;
          if (edge.edge_type === "positive") {
            co = 1;
          } else if (edge.edge_type === "categorical") {
            co = 0;
            // co = null;
          } else {
            co = -1;
          }
          edges.push({
            from: edge.source,
            to: edge.target,
            style: edge.edgeStyle,
            co: co,
          });
        } else if (edge.edgeStyle === "prohibit") {
          edges.push({
            from: edge.source,
            to: edge.target,
            style: edge.edgeStyle,
            co: 0,
          });
        } else {
          edges.push({
            from: edge.source,
            to: edge.target,
            style: edge.edgeStyle,
            co: edge.co,
          });
        }
      });
      return edges;
    },

    // search node
    findNode(nodeName) {
      const n = this.gData.nodes.find((node) => {
        return node.label === nodeName;
      });
      let nodeID = null;
      if (n) {
        nodeID = n.id;
      }
      const node = this.graph.findById(nodeID);

      if (node !== undefined) {
        this.graph.setItemState(node, "selected", true);
      }
    },

    //--------------------------------
    // unselect Node
    //--------------------------------
    cancelNodeSelect(nodeName) {
      const n = this.gData.nodes.find((node) => {
        return node.label === nodeName;
      });
      let nodeID = null;
      if (n) {
        nodeID = n.id;
      }
      const node = this.graph.findById(nodeID);
      this.graph.setItemState(node, "selected", false);
    },

    //--------------------------------
    // when navbar open resize the canvas
    //--------------------------------
    refreshGraph(state, width) {
      // console.log("refreshGraph");
      const DAG = document.getElementById("dag");
      const height = DAG.offsetHeight;
      let canvasSize;

      if (state == "close") {
        // console.log("ORI");
        canvasSize = DAG.offsetWidth + width;
      } else {
        canvasSize = DAG.offsetWidth - width;
      }
      try {
        this.graph.changeSize(canvasSize, height);
      } catch (e) {
        // this.graph.fitView();
      }
    },

    //--------------------------------
    // get importance value
    //--------------------------------
    getWeightArr() {
      return this.weightArr;
    },
    selectEdge(e) {
      this.weightArr = [];
      const g = this.graph;
      const item = e.target;
      const edge = e.item.getModel();
      // console.log(edge);
      if (!item) {
        return;
      }

      // Clear All Stats
      this.clearAllStats();

      // Set edge state
      if (edge.edge_type == "positive") {
        g.setItemState(edge.id, "positive", true);
      } else if (edge.edge_type == "negative") {
        g.setItemState(edge.id, "negative", true);
      } else if (edge.edge_type == "prohibited") {
        g.setItemState(edge.id, "prohibited", true);
      } else if (edge.edge_type == "categorical") {
        g.setItemState(edge.id, "categorical", true);
      } else {
        console.log("edge:", edge);
      }
    },

    //--------------------------------
    // click node
    //--------------------------------
    selectNode(e) {
      this.weightArr = [];
      const g = this.graph;
      const item = e.target;
      if (!item) {
        return;
      }
      // Clear All Stats
      this.clearAllStats();

      // find edge target =target
      const edges = item.getInEdges();
      edges.forEach((edge) => {
        const sourceId = edge.getModel().source;
        const node = g.cloneData.nodes.find((x) => {
          return x.id === sourceId;
        });
        if (edge.getModel().edgeStyle !== "prohibit") {
          this.weightArr.push({
            // [edge.getModel().source]: edge.getModel().weight,
            // [node.label]: edge.getModel().weight,
            name: node.label,
            source: edge.getModel().source,
            weight: edge.getModel().weight,
            datatype: node.details.datatype,
            co: edge.getModel().co,
            edge_type: edge.getModel().edge_type,
          });
        }
      });

      // repaint the canvas
      g.setAutoPaint(false);
      g.getNodes().forEach((node) => {
        g.clearItemStates(node);
        g.setItemState(node, "dark", true);
      });
      g.setItemState(item, "dark", false);
      g.setItemState(item, "highlight", true);
      breadthFirstSearch(g.cloneData, item._cfg.id, {
        leave: ({ current }) => {
          // 遍历完节点的回调
          if (g.findById(current)) {
            g.getEdges().forEach((edge) => {
              if (edge.getSource() in g.findById(current)) {
                // graph.updateItem(edge, { type: animate_type });
                // graph.setItemState(edge.getTarget(), "dark", false);
                // graph.setItemState(edge.getTarget(), "highlight", true);
                // graph.setItemState(edge, "highlight_out", true);
                // edge.toFront();
              } else if (edge.getTarget() === g.findById(current)) {
                g.setItemState(edge.getSource(), "dark", false);
                if (
                  edge.getModel().weight > 0 &&
                  edge.getModel().edge_type == "positive"
                ) {
                  g.setItemState(edge, "positive", true);
                  if (edge.getModel().type == "line") {
                    // console.log(edge.getModel().type);
                    g.updateItem(edge, { type: "circle-running-line" }, false);
                  } else if (edge.getModel().type == "quadratic") {
                    g.updateItem(
                      edge,
                      { type: "circle-running-quadratic" },
                      false
                    );
                  }
                } else if (
                  edge.getModel().weight < 0 &&
                  edge.getModel().edge_type == "negative"
                ) {
                  g.setItemState(edge, "negative", true);
                  if (edge.getModel().type == "line") {
                    g.updateItem(edge, { type: "circle-running-line" }, false);
                  } else if (edge.getModel().type == "quadratic") {
                    g.updateItem(
                      edge,
                      { type: "circle-running-quadratic" },
                      false
                    );
                  }
                } else if (
                  edge.getModel().weight > 0 &&
                  edge.getModel().edge_type == "categorical"
                ) {
                  g.setItemState(edge, "categorical", true);
                  if (edge.getModel().type == "line") {
                    g.updateItem(edge, { type: "circle-running-line" }, false);
                  } else if (edge.getModel().type == "quadratic") {
                    g.updateItem(
                      edge,
                      { type: "circle-running-quadratic" },
                      false
                    );
                  }
                }

                edge.toFront();
              }
            });
            g.setItemState(g.findById(current), "highlight", true);
          }
        },
      });

      g.paint();
      g.setAutoPaint(true);
    },

    //--------------------------------
    // clear node and edge state
    //--------------------------------
    clearAllStats() {
      const g = this.graph;
      g.setAutoPaint(false);
      g.getNodes().forEach((node) => {
        g.clearItemStates(node);
      });
      g.getEdges().forEach((edge) => {
        g.clearItemStates(edge);
      });
      g.paint();
      g.setAutoPaint(true);
      this.clearAnimation();
    },

    clearAnimation() {
      const g = this.graph;
      g.getEdges().forEach((edge) => {
        if (edge.getModel().type == "circle-running-line") {
          g.updateItem(edge, { type: "line" }, false);
        } else if (edge.getModel().type == "circle-running-quadratic") {
          g.updateItem(edge, { type: "quadratic" }, false);
        }
      });
    },

    //--------------------------------
    // use dourceData.json to render edge and node style
    //--------------------------------
    renderData(sourceData) {
      if (sourceData === null) {
        return;
      }

      sourceData.edges.forEach((edge, i) => {
        edge.id = "edge" + i;

        // Combinations
        // ai - positive
        // ai - negative
        // ai - categorical
        // expert - positive
        // export - negative
        // export - categorical
        // prohibit

        // Style
        if (edge.edgeStyle === "ai") {
          edge.style = AI_Edge_Style;
          // edge.size = Math.abs((Number(edge.imp) * 10).toFixed(0)) * 1.5;
        } else if (edge.edgeStyle === "expert") {
          edge.style = Expert_Edge_Style;
          // edge.size = Math.abs((Number(edge.imp) * 10).toFixed(0)) * 1.5;
        } else if (edge.edgeStyle === "prohibit") {
          edge.style = Expert_Edge_Style;
          edge.edge_type = "prohibited";
          edge.label = "x";
          // edge.size = 10;
        }

        const defualtSize = 5;
        // Size
        if (edge.edgeStyle === "ai") {
          edge.size = Math.abs((Number(edge.imp) * 10).toFixed(0)) * 1.5;
        } else if (edge.edgeStyle === "expert" && edge.customCo === 1) {
          edge.size = defualtSize; // positive expert
        } else if (edge.edgeStyle === "expert" && edge.customCo === -1) {
          edge.size = defualtSize; // negative expert
        } else if (edge.edgeStyle === "expert" && edge.customCo === 0) {
          edge.size = defualtSize; // categorical expert; To be checked
        } else if (edge.edgeStyle === "expert" && edge.customCo === null) {
          edge.size = defualtSize; // categorical expert; To be checked
        } else if (edge.edgeStyle === "expert") {
          edge.size = Math.abs((Number(edge.imp) * 10).toFixed(0)) * 1.5; // positive or negative expert; To be checked
        } else if (edge.edgeStyle === "prohibit") {
          edge.size = defualtSize;
        }

        // Weight
        if (edge.edgeStyle === "prohibit") {
          edge.weight = 0;
        }
        // else if (edge.customCo === 1 && edge.edgeStyle === "expert") {
        //   edge.weight = 1;
        //   edge.size = 10;
        // } else if (edge.customCo === -1 && edge.edgeStyle === "expert") {
        //   edge.weight = -1;
        //   edge.size = 10;
        // } else if (edge.customCo === null && edge.edgeStyle === "expert") {
        //   edge.size = 10;
        //   edge.weight = edge.imp;
        // } else if (edge.customCo === 0 && edge.edgeStyle === "expert") {
        //   edge.size = 10;
        //   edge.weight = edge.imp;
        // }
        else if (edge.customCo >= 0) {
          edge.weight = edge.imp.toFixed(3);
        } else {
          edge.weight = edge.imp.toFixed(3) * -1;
        }

        edge.source = edge.from;
        edge.target = edge.to;

        // edge_type
        if (edge.customCo > 0) {
          edge.edge_type = "positive";
        } else if (edge.customCo < 0) {
          edge.edge_type = "negative";
        } else {
          // Categorical
          edge.edge_type = "categorical";
        }
      });

      this.cloneData(sourceData);

      //初始化的图数据，是一个包括 nodes 数组和 edges 数组的对象。
      this.graph.data(sourceData);
      this.graph.render();
    },

    //--------------------------------
    // for breathsearching find relation node
    //--------------------------------
    cloneData(sourceData) {
      this.graph.cloneData = {
        nodes: sourceData.nodes,
        edges: sourceData.edges.map((edge) => ({
          source: edge.target,
          target: edge.source,
        })),
      };
      // console.log(`this.graph.cloneData: ${this.graph.cloneData}`);
      // console.log(`{this.graph.cloneData}`);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style>
.g6-toolbar-ul {
  width: 0px;
}
</style>
